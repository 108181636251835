import React from 'react'




function App() {
    
    return (
        <div id="page-top">
           <nav className="navbar navbar-expand-lg navbar-light fixed-top py-3 bg-dark" id="mainNav">
                <div className="container">
                    <a className="navbar-brand js-scroll-trigger" href="#page-top"><img src="assets/img/logo.png" alt="logo" width="40px" />IGA KOTAMOBAGU</a>
                    <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
                    <div className="collapse navbar-collapse" id="navbarResponsive">
                        <ul className="navbar-nav ml-auto my-2 my-lg-0">
                            <li className="nav-item"><a className="nav-link js-scroll-trigger" href="#">HOME</a></li>
                            <li className="nav-item"><a className="nav-link js-scroll-trigger" href="#about">LIST PEMENANG IGA 2020</a></li>
                            <li className="nav-item"><a className="nav-link js-scroll-trigger" href="#portfolio">PEMBERIAN HADIAH</a></li>
                            <li className="nav-item"><a className="nav-link js-scroll-trigger" href="#contact">KONTAK</a></li>
                        </ul>
                    </div>
                </div>
            </nav>

            <header className="masthead mt-5">
                <div className="container h-100">
                    <div className="row h-100 align-items-center justify-content-center text-center">
                        <div className="col-lg-10 align-self-end">
                            <h1 className="text-uppercase text-white font-weight-bold">INNOVATIVE GOVERNMENT AWARD (IGA)</h1>
                            <hr className="divider my-4" />
                        </div>
                        <div className="col-lg-8 align-self-baseline">
                            <p className="text-white-75 font-weight-light mb-5">2020</p>
                            <a className="btn btn-primary btn-xl js-scroll-trigger" href="#about">LIHAT PEMENANG</a>
                        </div>
                    </div>
                </div>
            </header>

             {/* Call to action */}
            <section class="page-section bg-dark text-white">
                <div class="container text-center">
                    <img src="assets/img/poster.jpeg"/>
                </div>
            </section>

            {/* <!-- About--> */}
            <section class="page-section bg-primary" id="about">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-12 text-center">
                            <h2 class="text-white mt-0">DAFTAR PEMENANG IGA 2020</h2>
                            <hr class="divider light my-4" />
                            <div class="row">
                                <div class="col-md-6">
                                    <a class="portfolio-box" href="assets/img/pemenang/1.jpg">
                                        <img class="img-fluid" src="assets/img/pemenang/1.jpg" alt="" />
                                    </a>
                                </div>

                                <div class="col-md-6">
                                    <a class="portfolio-box" href="assets/img/pemenang/2.jpg">
                                        <img class="img-fluid" src="assets/img/pemenang/2.jpg" alt="" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            
            {/* <!-- Portfolio--> */}
            <div id="portfolio" class="page-section">
                <div class="container" >
                        <h2 class="text-center mt-0">PEMBERIAN HADIAH KEPADA PARA PEMENANG</h2>
                        <hr class="divider my-4" />
                        <div class="row">
                        </div>
                    </div>
                    
                <div class="container-fluid p-0">
                    <div class="row no-gutters">
                        <div class="col-lg-4 col-sm-6">
                            <a class="portfolio-box" href="assets/img/kegiatan/1.jpg">
                                <img class="img-fluid" src="assets/img/kegiatan/1.jpg" alt="" />
                                <div class="portfolio-box-caption">
                                    <div class="project-category text-white-50">Category</div>
                                    <div class="project-name">Project Name</div>
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <a class="portfolio-box" href="assets/img/kegiatan/2.jpg">
                                <img class="img-fluid" src="assets/img/kegiatan/2.jpg" alt="" />
                                <div class="portfolio-box-caption">
                                    <div class="project-category text-white-50">Category</div>
                                    <div class="project-name">Project Name</div>
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <a class="portfolio-box" href="assets/img/kegiatan/3.jpg">
                                <img class="img-fluid" src="assets/img/kegiatan/3.jpg" alt="" />
                                <div class="portfolio-box-caption">
                                    <div class="project-category text-white-50">Category</div>
                                    <div class="project-name">Project Name</div>
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <a class="portfolio-box" href="assets/img/kegiatan/4.jpeg">
                                <img class="img-fluid" src="assets/img/kegiatan/4.jpeg" alt="" />
                                <div class="portfolio-box-caption">
                                    <div class="project-category text-white-50">Category</div>
                                    <div class="project-name">Project Name</div>
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <a class="portfolio-box" href="assets/img/kegiatan/5.jpg">
                                <img class="img-fluid" src="assets/img/kegiatan/5.jpg" alt="" />
                                <div class="portfolio-box-caption">
                                    <div class="project-category text-white-50">Category</div>
                                    <div class="project-name">Project Name</div>
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <a class="portfolio-box" href="assets/img/kegiatan/6.jpg">
                                <img class="img-fluid" src="assets/img/kegiatan/6.jpg" alt="" />
                                <div class="portfolio-box-caption p-3">
                                    <div class="project-category text-white-50">Category</div>
                                    <div class="project-name">Project Name</div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            
            
            {/* Contact */}
            <section class="page-section" id="contact">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-8 text-center">
                            <h2 class="mt-0">KONTAK</h2>
                            <hr class="divider my-4" />
                            <p class="text-muted mb-5">Hubungi Panitia Pelaksana</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 ml-auto text-center mb-5 mb-lg-0">
                            <i class="fas fa-phone fa-3x mb-3 text-muted"></i>
                            <div>+1 (555) 123-4567</div>
                        </div>
                        <div class="col-lg-4 mr-auto text-center">
                            <i class="fas fa-envelope fa-3x mb-3 text-muted"></i>
                            {/* <!-- Make sure to change the email address in BOTH the anchor text and the link target below!--> */}
                            <a class="d-block" href="mailto:contact@yourwebsite.com">contact@yourwebsite.com</a>
                        </div>
                    </div>
                </div>
            </section>
            
            {/* footer */}
            <footer class="bg-light py-5">
                <div class="container"><div class="small text-center text-muted">Copyright © 2020 - Pemerintah Kota Kotamobagu | suport template by (Start Bootstrap)</div></div>
            </footer>

        </div>
    )
}

export default App


